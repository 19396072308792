


.custom-shape-divider-bottom-1712072085 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1712072085 svg {
  position: relative;
  display: block;
  width: calc(144% + 1.3px);
  height: 171px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1712072085 .shape-fill {
  fill: #ffffff;
}

.mask-with-browser-support {
  mask: linear-gradient(black, black), linear-gradient(black, black);
  mask-clip: content-box, border-box;
  mask-composite: exclude;
  -webkit-mask:
    linear-gradient(black, black) content-box,
    linear-gradient(black, black);
  -webkit-mask-clip: content-box, border-box;
  -webkit-mask-composite: xor;
}









* {
  scroll-behavior: smooth;
  scroll-snap-type: mandatory;
  box-sizing: border-box;
  transition: transform 0.3s ease-in-out;
}



#logo_sizing {
  height: 125px;
  width: 100%;
  display: flex;
}





#why_buy {
  display: flex;
  justify-content: center;
    width: 100%;
}
  #why_buy img {
    height: 5rem;
    /* width: 50%; */
    /* padding-top: 10px; */
    margin-bottom: 20px;
    margin-top: 20px;
  }


  #why_buy1 {
    display: flex;
    justify-content: center;
      width: 100%;
  }
    #why_buy1 img {
      height: 10rem;
      /* width: 50%; */
      /* padding-top: 10px; */
      margin-bottom: 20px;
      margin-top: 20px;
    }


  @media screen and (min-width: 1024px) {
    .numm1 {
        flex-direction: row;
        display: flex;
    }

}


/* PRODUCTS SECTION */

.view_all {
    display: flex;
    justify-content: center;
}


.product_array {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #F4F0E0;
}



#leaf {
    background-color: #F4F0E0;
}





#products_container {
    padding-top: 100px;
    display: flex;
    flex-wrap: wrap;
    background-color: #F4F0E0;
}

#product_div {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}




/* #singlepageproductimage {
    width: 100%;
    height: 450px;
} */


.added {
    transition: all 0.3s ease;
    background-color: lightgreen;
  }

  .dotted-line {
    border-top: 1px dotted #000;
    width: 100%;
  }

  .rique {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  


  #product_form {
    /* padding: 50px; */
  }

  .mom {
    display: flex;
    align-items: stretch;
  }


  .lala {
    display: flex;
    align-items: center;
    align-content: center;
  }

 


/* HOW TO USE */

#questions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: center;
    justify-content: center;
}

#newhero {
    width: 100%;
    position: relative;
    height: auto;
    background-color: #F4F0E0;
    padding: 10%;
}

@media screen and (max-width: 1024px) {
  #centerednew {
    margin-top: 8%;
    position: relative;
  }
}

@media screen and (max-width: 359px) {
  #centerednew {
    margin-top: 5%;
    position: relative;
  }
}
@media screen and (max-width: 768px) {
  #newhero {
    margin-top: 100px;
  }
}

/* YourComponent.css */

.custom-button {
  width: 50%;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 40px;
  margin-top: 15px;
  background-color: #77976C !important;
  color: white !important;
}

.custom-button:hover {
  background-color: white !important;
  color: #77976C !important;
}

.custom-button:active {
  background-color: #77976C !important;
  color: white !important;
}






/* @media screen and (min-width:1024px) {
    #newhero img {
        min-width: 800px;
    }
} */





.sms_topbar {
    text-align: center;
    color: #557174;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: absolute;
    left: 45%;
    /* animation: slide-in 15s ease-in-out infinite; */
}

  /* @keyframes slide-in {
    0% {
      left: -100%;
    }
    50% {
      left: 0;
    }
    100% {
      left: 100%;
    }
  } */
  
  /* .sms_topbar.active {
    animation: slide-out 20s ease-in-out infinite;
  } */
  
  /* @keyframes slide-out {
    0% {
      left: 0;
    }
    50% {
      left: 50%;
    }
    100% {
      left: 100%;
    }
  }
   */


label {
  font-family: 'Bebas Neue';
  color: #557174;
}



/* FOOTER */

.footer_bg {
  /* height: 25%; */
  background-color: #557174;
  color: white;
  display: flex;
  padding: 35px;
  position: relative;
  width: 100%;
}

.footer_outer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}


#foot_in {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 2%;
  align-content: center;
}



.bake-in-out {
  transition: opacity 0.3s ease-in-out;
}

.bake-in-out.fade-in {
  opacity: 1;
}

.bake-in-out.fade-out {
  opacity: 0;
}











.about-wrapper{
  display: flex;
  flex-direction: column;
  margin-bottom: 10%;
  position: relative;
  margin-top: 100px;
}

@media screen and (min-width: 1024px) {
  .about-wrapper {
    height: 100dvh;
  }
}

.about-left{
  background: #F4F0E0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: relative; */
}
.about-left-content > div{
  background: #77976C;
  padding: 4rem 4rem 2.5rem 5rem;
  text-align: center;
  /* position: relative; */
}
.about-left-content{
  box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.39);
  -webkit-box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 0px 18px -1px rgba(0, 0, 0, 0.39);
  /* position: relative; */
}
.about-img img{
  display: block;
  width: 200px;
  background-color: white;
}
.about-img{
  width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
}
.shadow{
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  position: relative;
  cursor: pointer;
}
.shadow::after{
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  left: 50%;
  transform: translateX(-50%);
  bottom: -20px;
  height: 7px;
  width: 50px;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  opacity: 0;
  transition: all 0.6s ease;
}
.shadow:hover .about-img{
  transform: translateY(-10px);
}
.shadow:hover::after{
  opacity: 1;
}
.about-left-content h3{
  font-size: 2rem;
  margin: 2.2rem 0 0.6rem 0;
  line-height: 1.2;
  padding-bottom: 1rem;
  border-bottom: 2px solid #edffec;
}
.about-left-content h3{
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 5px;
  margin-top: 1.2rem;
}
.icons{
  background: #fff;
  display: flex;
  justify-content: center;
  padding: 0.8rem 0;
}
.icons li a{
  list-style-type: none;
  background: #557174;
  color: #fff;
  width: 40px;
  height: 40px;
  margin: 0 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.icons li:hover{
  background: #edffec;
  color: #000;
}
.about-right{
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
  text-align: center;
}

.about-right1{
  background: #fff;
  height:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
  text-align: center;
}

@media screen and (min-width: 912px) {
  .about-right {
    height: 50dvh;
  }
}

@media screen and (min-width: 913px) {
  .about-right {
    height: 100%;
  }
}

@media  screen and (min-width: 1024px) and (min-height: 600px) {
  .about-right {
    height: 100%;
  }
}

@media  screen and (min-width: 1024px) {
  .about-right {
    height: 100%;
  }
}



.about-right h2{
  font-size: 7rem;
  text-transform: uppercase;
}
.about-right h1 span{
  color: #557174;
}
.about-right h2{
  font-weight: 600;
}
.about-btns{
  display: flex;
  margin: 2rem 0;
}
.btn{
  border: none;
  font-size: 0.9rem;
  text-transform: uppercase;
  border: 2px solid #000;
  border-radius: 20px;
  padding: 0.55rem 0;
  width: 130px;
  font-weight: 600;
  background: transparent;
  margin: 0 0.5rem;
  cursor: pointer;
}
.btn.btn-pink {
  background:#77976C;
  color: white;
  border-color: #77976C;
  transition: all 0.5s ease-in-out;
}
.btn.btn-pink a:hover{
  background: transparent;
  border-color: #77976C;
  color: #000;
}
.btn.btn-white{
  transition: all 0.5s ease-in-out;
}
.btn.btn-white:hover{
  background: #77976C;
  border-color: #77976C;
  color: #fff;
}
.about-para p{
  font-weight: 300;
  padding: 0.5rem;
  opacity: 0.8;
}

@media screen and (min-width: 992px){
  .about-wrapper{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
  }
  .about-left{
      position: relative;
  }
  .about-left-content{
      position: absolute;
      right: -50px;
  }
}


.dropdown:hover .dropdown-content {
  display: inline-block;
}

.dropdown-menu {
  position: absolute;
  top: 35px;
}






.wt {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: 1px solid #e7e7e7;
  background-color: #f3f3f3;
}

.yt {
  float: left;
}

.yt a {
  display: block;
  color: #777;
  text-align: center;
  padding: 8px 10px;
  text-decoration: none;
}

.yt a:hover:not(.active) {
  background-color: #ddd;
}

.yt a.active {
  color: white;
  background-color: #04AA6D;
}



#alert_modal {
  position: absolute;
  right: 10%;
}




.diagonal-div1 {
  width: 100%;
  min-height: 100%;
  padding: 20px;
}